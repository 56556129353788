<template>
<div class="tgju-widget-content full-padding">
    <div class="row tgju-widgets-row">
        <div class="tgju-widgets-block col-12 widget-border-fix">
            <Following :username="this.$route.params.username"></Following>
        </div>
    </div>
</div>
</template>

<style lang="scss" scoped>
.widget-min-box-content {
    .l-title {
        display: flex;
    }

    .edit-note {
        font-size: 14px;
        cursor: pointer;
        margin-right: -3px;
    }
}
</style>

<script>
// این ویو برای صفحه فالوئر های پروفایل دیگر کاربران میباشد
import Following from '@/components/ProfileOthers/Following.vue'

export default {
    name: 'ProfileOthersFollowingView',
    props: [],
    components: {
        Following,
    },
    data() {
        return {}
    },
    mounted() {},
    methods: {},
}
</script>
